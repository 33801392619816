.budgets-page--wrapper {
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    .budgets-page--header {
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--color-gray-300);

        .budgets-header--left {
            align-items: center;

            .budgets-heading {
                font-size: var(--font-size-30);
                font-family: var(--font-family-gotham-bold);
                // color: var(--color-black-600);
            }
        }

        .budgets-header--right {

            // .location-select-wrapper {

            //     .location-select {
            //         border-color: var(--color-gray-300);
            //         border-radius: 7px;
            //         border: 2px solid var(--color-gray-300);
            //         font-size: var(--font-size-14);
            //         min-width: 250px;
            //     }
            // }

            .budgets-upload-btn {
                margin-left: 10px;
            }

        }
    }

    .budgets-page--body {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
    }
}